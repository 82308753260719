/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
    i18n: "Home"
  },
  {
    url: "/Video",
    name: "Video",
    slug: "Video",
    icon: "VideoIcon",
    show: true,
    i18n: "Video"
  },

  {
    url: "/News",
    name: "News",
    slug: "News",
    icon: "BookIcon",
    show: true,
    i18n: "News"
  },

  {
    url: "/User",
    name: "User",
    slug: "User",
    icon: "UserIcon",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
    i18n: "User",
    submenu:[
      {
        url: "/User/AppUser",
        name: "AppUser",
        slug: "AppUser",
        icon: "UserIcon",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
        i18n: "AppUser"
      },
      {
        url: "/User/Expert",
        name: "Expert",
        slug: "Expert",
        icon: "UserIcon",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
        i18n: "Expert"
      },
    ]
  },
  {
    url: "/Setting",
    name: "Setting",
    slug: "Setting",
    icon: "UserIcon",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
    i18n: "Setting",
    submenu:[
      {
        url: "/Settings/Country",
        name: "Country",
        slug: "Country",
        icon: "UserIcon",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
        i18n: "Country"
      },
      {
        url: "/Settings/City",
        name: "City",
        slug: "City",
        icon: "UserIcon",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
        i18n: "City"
      },
      {
        url: "/Settings/District",
        name: "District",
        slug: "District",
        icon: "UserIcon",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
        i18n: "District"
      }
    ]
  },

  {
    url: "/Competition",
    name: "Competition",
    slug: "Competition",
    icon: "AwardIcon",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
    i18n: "Competitions"
  },


];



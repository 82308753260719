<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template functional>
  <footer class="the-footer flex-wrap justify-between" :class="classes">
    <!-- <span>COPYRIGHT @ {{ new Date().getFullYear() }} <a href="https://i-valley.com/" target="_blank" rel="nofollow">I-Valley</a>, All rights Reserved</span> -->
 <span >COPYRIGHT @ {{ new Date().getFullYear() }} <a>TikiTka</a>
 <a  href="https://i-valley.com/" target="_blank"> <img style="padding-top:10px" src="@/assets/images/IValleyLogo.png" width="60" height="30" alt=""> </a>
     <!-- <a href="https://biadjosolutions.com/" target="_blank"> <img style="padding-top:10px" src="@/assets/images/BiadjoLogo.png" width="60" height="30" alt="">  </a>  -->
     , All rights Reserved  
     </span>
    
    <!-- <span class="md:flex hidden items-center">
            <span>Hand-crafted & Made with</span>
            <feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 h-6" class="ml-2" />
        </span> -->
  </footer>
</template>

<script>
export default {
    data() {
    return {
      
      CountryCode: "null"
    };
  },
  name: "the-footer",
  props: {
    classes: {
      type: String
    }
  }
};
</script>

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <!-- <vs-navbar
        class="navbar-custom navbar-skelton"
        style="background-image: url('http://173.249.43.159:89/navbarheader.png')"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      > -->
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>
        <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

        <!-- <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        > -->
        <div
          v-if="windowWidth >= 992"
          class="absolute left-0 top-0 flex cursor-pointer mx-auto mt-4 sm:ml-6 md:ml-6 lg:ml-6 xl:ml-6 ml-20"
        >
          <transition :name="routerTransition">
            <div
              v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              class="router-header flex flex-wrap items-center mb-6"
            >
              <div
                class="content-area__heading"
                :class="{
                  'pr-4 border-0 md:border-r border-solid border-grey-light':
                    $route.meta.breadcrumb
                }"
              >
                <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
              </div>

              <!-- BREADCRUMB -->
              <vx-breadcrumb
                class="ml-4 md:block hidden"
                v-if="$route.meta.breadcrumb"
                :route="$route"
                :isRTL="$vs.rtl"
              />
            </div>
          </transition>
        </div>
        <!-- <logo class="w-10 mr-4 fill-current ٍٍtext-primary" />
        <span class="vx-logo-text text-primary">Biajdo Admin Portal</span> -->

        <!-- <logo class="w-10 mr-4 fill-current text-primary" /> -->
        <!-- <span class="vx-logo-text text-primary">{{
            $t("ProjectTitle")
          }}</span> -->
        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        >
        </router-link>
        <div class="text-right leading-tight hidden sm:block ml-3 mr-3 ">
          <p class="font-semibold" style="color:#3268a8">{{ user_Email }}</p>
          <small style="color:#3268a8">{{ $t("Welcome") }}</small>
        </div>
        <i18n style="padding-right: 20px; padding-left: 20px;" />
        <notification-drop-down />
        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import I18n from "./components/I18n.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import themeConfig from "@/../themeConfig.js";
import NotificationDropDown from "./components/NotificationDropDown.vue";

export default {
  name: "the-navbar-horizontal",
  props: {},
  data() {
    return {
      routeTitle: this.$route.meta.pageTitle,
      routerTransition: themeConfig.routerTransition || "none"
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    }
  },
  components: {
    // Logo,
    // Bookmarks,
    I18n,
    // SearchBar,
    // CartDropDown,
    NotificationDropDown,
    ProfileDropDown
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_Email() {
      return this.activeUserInfo.Email;
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL;
    },
    navbarColor() {
      let color = "#fff";
      // if (this.navbarType === "sticky") {
      //   color = "#f7f7f7";
      // } else if (this.navbarType === "static") {
      //   if (this.scrollY < 50) {
      //     color = "#f7f7f7";
      //   }
      // }

      // if (this.isThemedark === "dark") {
      //   if (color === "#fff") {
      //     color = "#10163a";
      //   } else {
      //     color = "#262c49";
      //   }
      // }

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    }
  }
};
</script>

<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon
      icon="BellIcon"
      class="cursor-pointer mt-1 sm:mr-6 mr-2"
      :badge="NotificationsLength"
    />

    <vs-dropdown-menu
      class="notification-dropdown dropdown-custom vx-navbar-dropdown"
    >
      <div
        v-if="Notifications.length > 0"
        class="notification-top flex justify-between text-center p-5 bg-primary text-white"
      >
        <div class="flex items-start">
          <h3 class="text-white">{{ NotificationsLength }} {{ $t("New") }}</h3>
        </div>
        <div class="flex items-end">
          <span
            class="text-right text-xs cursor-pointer"
            @click="MarkAsReadAll"
          >
            {{ $t("MarkAsReadAll") }}
          </span>
        </div>
        <!-- <p class="opacity-75">App Notifications</p> -->
      </div>

      <VuePerfectScrollbar
        v-if="Notifications.length > 0"
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="bordered-items pb-8 mb-4">
          <li
            v-for="(ntf, index) in Notifications"
            :key="index"
            class="flex justify-between px-4 py-4 notification cursor-pointer"
            :style="[ntf.IsRead?{}:{backgroundColor:'#bdbcb9'}]"
            @click="routeToNotificationPage(ntf,index)"
          >
            <div class="flex items-start w-full " >
              <!-- <feather-icon
                :icon="ntf.icon"
                :svgClasses="[
                  `text-${ntf.category}`,
                  'stroke-current mr-1 h-6 w-6'
                ]"
              ></feather-icon> -->
              <div class="mx-2" >
                <small :class="[`font-${getStatus(ntf.IsRead)}`]">{{
                  ntf.SenderName
                }}</small>
                <span
                  class="font-medium block notification-title"
                  :class="[`font-${getStatus(ntf.IsRead)}`]"
                  >{{ ntf.Title }}</span
                >
                <!-- <img
                  v-if="ntf.URL"
                  :src="baseURL + ntf.URL"
                  class="Circle rounded-circle z-depth-1 dropdown-toggle"
                  alt="Default-user-img"
                  width="40"
                  height="40"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-holder-rendered="true"
                /> -->
                <p :class="[`font-${getStatus(ntf.IsRead)}`]" >
                  {{ ntf.Description }}
                </p>
              </div>
            </div>
            <div class="flex items-end">
              <small :class="[`font-${getStatus(ntf.IsRead)}`]">{{
                ntf.CreatedDate | formatDate()
              }}</small>
              <vx-tooltip text="Mark As Read">
                <feather-icon
                  icon="BookOpenIcon"
                  svgClasses="stroke-current mr-2 ml-2 h-5 w-5 text-primary"
                  @click="markAsRead(index, ntf.ID)"
                ></feather-icon>
              </vx-tooltip>
              <!-- <vx-tooltip text="Remove Notification">
                <feather-icon
                  icon="DeleteIcon"
                  @click="deleteNotification(index, ntf.UserNotificationID)"
                  svgClasses="stroke-current mr-2 ml-2 h-5 w-5 text-danger"
                ></feather-icon>
              </vx-tooltip> -->
            </div>
            <!-- <small class="mt-1 whitespace-no-wrap">{{
              elapsedTime(ntf.time)
            }}</small> -->
          </li>
        </ul>
      </VuePerfectScrollbar>

      <VuePerfectScrollbar
        v-else
        ref="mainSidebarPs"
        class="scroll-area--nofications-dropdown p-0 mb-10"
        :settings="settings"
        :key="$vs.rtl"
      >
        <ul class="m-4 justify-center">
          <li class="flex justify-center px-4 py-4 notification">
            {{ $t("NONotifications") }}
          </li>
        </ul>
      </VuePerfectScrollbar>

      <div
        v-if="Notifications.length > 0"
        class="checkout-footer fixed flex justify-between bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light"
      >
        <router-link
          class="pl-5 cursor-pointer"
          :to="{
            name: 'Notifications'
          }"
        >
          <!-- <span>{{ $t("ViewAllNotifications") }}</span> -->
        </router-link>
        <!-- <span
          @click="deleteAllNotification"
          class="pr-5 text-xs cursor-pointer"
          >{{ $t("حذف الكل") }}</span
        > -->
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      baseURL: domain,
      domain: this.$http.defaults.baseURL,
      body: "",
      ID: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    Notifications() {
   
      return this.$store.state.NotificationList;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    NotificationsLength() {
     
      let total = 0;
      this.$store.state.NotificationList.forEach(element => {
        if (element.IsRead == 0) {
          total = total + 1;
        }
      });
      return total;
    }
  },
  methods: {
    GoToNotifyURL(){

    },
    markAsRead(index, ID) {
      debugger
      // this.$vs.loading();
     
      this.$store.commit("update_NotificationSeen", index);
      this.$store.dispatch("auth/MarkIsReadNotification", ID);

      // Add mutation to update status to be true for current notification with index
    },

    MarkAsReadAll() {
      // this.$vs.loading();
      for (let i = 0; i < this.Notifications.length; i++) {
        this.$store.commit("update_NotificationSeen", i);
        this.$store.dispatch("auth/MarkIsReadNotification", this.Notifications[i].ID);
      }

      // Add mutation to update status to be true for current notification with index
    },

    // deleteNotification(index, ID) {
    //   //DELTE NOTIFICATIOB BY INDEX FROM ARRAY
    //   this.$store.commit("RemoveFrom_NotificationList", index);
    //   this.$store.dispatch("auth/MarkIsNewNotificationByNotify", ID);
    // },
    // deleteAllNotification() {
    //   //DELTE NOTIFICATIOB BY INDEX FROM ARRAY
    //   this.$store.commit("RemoveFrom_AllNotificationList", []);
    //   this.$store.dispatch("auth/DeleteAllNotification");
    // },
    // getTitle(notification) {
    //   switch (notification.Type) {
    //     case "RecieveDemoProjectRequest":
    //       return this.$t("RecieveDemoProjectRequest");

    //     case "NewOffer":
    //       return this.$t("NewOffer");

    //     case "ConflictedDemoProject":
    //       return this.$t("ConflictedDemoProject");

    //     case "OfferAccepted":
    //       return this.$t("OfferAccepted");

    //     case "DriverWaitingForVerfication":
    //       return this.$t("DriverWaitingForVerfication");

    //     case "NewDemoProject":
    //       return this.$t("NewDemoProject");

    //     case "DemoProjectWithoutDrivers":
    //       return this.$t("DemoProjectWithoutDrivers");

    //     case "OffersWaitingForApproval":
    //       return this.$t("OffersWaitingForApproval");

    //     case "DoneDemoProjects":
    //       return this.$t("DoneDemoProjects");

    //     case "UnstartedDemoProjects":
    //       return this.$t("UnstartedDemoProjects");

    //     default:
    //       return "";
    //   }
    // },

    // getMessage(notification) {
    //   switch (notification.Type) {
    //     case "RecieveDemoProjectRequest":
    //       return (
    //         this.$t("bodyRecieveDemoProjectRequest") + ": " + notification["ID"]
    //       );
    //     case "NewOffer":
    //       return this.$t("BodyNewOffer") + ": " + notification["ID"];

    //     case "OfferAccepted":
    //       return this.$t("BodyOfferAccepted") + ": " + notification["ID"];

    //     case "ConflictedDemoProject":
    //       return (
    //         this.$t("BodyConflictedDemoProject") +
    //         ": " +
    //         notification["DemoProjects_Count"]
    //       );

    //     case "DriverWaitingForVerfication":
    //       return (
    //         this.$t("BodyDriverWaitingForVerfication") +
    //         ": " +
    //         notification["ID"]
    //       );

    //     case "NewDemoProject":
    //       return this.$t("BodyNewDemoProject") + ": " + notification["ID"];

    //     case "DemoProjectWithoutDrivers":
    //       return (
    //         this.$t("BodyDemoProjectWithoutDrivers") + ": " + notification["ID"]
    //       );
    //     case "OffersWaitingForApproval":
    //       return (
    //         this.$t("BodyOffersWaitingForApproval") + ": " + notification["ID"]
    //       );

    //     case "DoneDemoProjects":
    //       return this.$t("BodyDoneDemoProjects") + ": " + notification["ID"];

    //     case "UnstartedDemoProjects":
    //       return (
    //         this.$t("BodyUnstartedDemoProjects") + ": " + notification["ID"]
    //       );

    //     default:
    //       return "";
    //   }
    // },
    getStatus(notification) {
      switch (notification) {
        case 1:
          return "thin";

        case 0:
          return "semibold";

        default:
          return "thin";
      }
    },
    routeToNotificationPage(ntf, index) {
      this.markAsRead(index, ntf.ID);
      debugger
      switch (ntf.NotificationTypeID) {
        
        case 3:
          this.$router
            .push({ name: "ChannelBookChat" })
            .then(() => location.reload("/"));
          break;

        case 4:
          if(this.$store.state.AppActiveUser.UserType == 'Parent'||this.$store.state.AppActiveUser.UserType == 'Student'){
           this.$router
            .push({ name: "ScheduleAndEventsStudent" })
            .then(() => location.reload("/"));
          }
          else{
            this.$router
            .push({ name: "HomeTeacher" })
            .then(() => location.reload("/"));
          }
          break;

        case 5:
          this.$router
            .push({ name: "NewExams" })
            .then(() => location.reload("/"));
          break;

          case 6:
          this.$router
            .push({ name: "SubjectModules" })
            .then(() => location.reload("/"));
          break;
      }
    }
  }
};
</script>
<style>
.CustomHeight {
  height: 50vh;
}
</style>
